<template>
  <Layout>
    <template v-if="this.$route.name == 'add-cme'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row" id="case-carousel">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard
              color="#556ee6"
              enctype="multipart/form-data"
              ref="cme"
              :finishButtonText="
                this.$route.name == 'add-cme' ? 'Save Data' : 'Update Data'
              "
              @on-complete="submitData"
            >
              <!-- :before-change="() => validateFormOne()" -->
              <tab-content
                icon="mdi mdi-comment"
                :before-change="() => validateFormOne()"
              >
                <div class="row">
                  <div class="col-12">
                    <b-form ref="step1">
                      <div class="row">
                        <b-form-group class="col-6">
                          <label for="input-1"
                            >Type <span style="color: red">*</span></label
                          >
                          <b-form-select
                            id="input-1"
                            @change="changeCMEType"
                            :options="cmetype"
                            value-field="value"
                            text-field="text"
                            v-model="form.type"
                            :class="{
                              'is-invalid': tabOne && $v.form.type.$invalid,
                            }"
                          >
                          </b-form-select>
                          <div
                            v-if="tabOne && !$v.form.type.required"
                            class="invalid-feedback"
                          >
                            Type is required.
                          </div>
                        </b-form-group>
                        <b-form-group class="col-6">
                          <label for="input-2"
                            ><span style="text-transform: capitalize">{{
                              form.type
                            }}</span>
                            Title <span style="color: red">*</span></label
                          >
                          <b-form-input
                            id="input-2"
                            placeholder="Enter Title"
                            v-model="form.name"
                            :class="{
                              'is-invalid': tabOne && $v.form.name.$invalid,
                            }"
                          ></b-form-input>
                          <div
                            v-if="tabOne && !$v.form.name.required"
                            class="invalid-feedback"
                          >
                            Name is required.
                          </div>
                        </b-form-group>
                      </div>

                      <b-form-group>
                        <label for="i3"
                          >Landing Page Description
                          <span style="color: red">*</span></label
                        >
                        <ckeditor
                          v-model="form.description"
                          :editor="editor"
                          :class="{
                            'is-invalid':
                              tabOne && $v.form.description.$invalid,
                          }"
                        ></ckeditor>
                        <div
                          v-if="tabOne && !$v.form.description.required"
                          class="invalid-feedback"
                        >
                          Landing Page Description is required.
                        </div>
                      </b-form-group>

                      <div class="row">
                        <b-form-group class="col-6 position-relative">
                          <label for="input-4"
                            >Heading Text
                            <span style="color: red">*</span></label
                          >
                          <b-form-input
                            id="input-4"
                            :maxlength="250"
                            placeholder="Enter Heading Text [Eg: PDUI - Demam Typoid - Mock Assessment Test]"
                            v-model="form.heading_text"
                            :class="{
                              'is-invalid':
                                tabOne && $v.form.heading_text.$invalid,
                            }"
                          ></b-form-input>
                          <div
                            v-if="tabOne && !$v.form.heading_text.required"
                            class="invalid-feedback"
                          >
                            Heading Text is required.
                          </div>
                          <div class="text-right">
                            <p
                              v-if="form.heading_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success':
                                  form.heading_text.length !== 250,
                                'badge-danger':
                                  form.heading_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.heading_text.length }} out of 250 chars.
                            </p>
                          </div>
                        </b-form-group>
                        <b-form-group
                          class="col-6 position-relative"
                          id="input-group-6"
                          label="Landing Page Button Text"
                          label-for="input-10"
                        >
                          <b-form-input
                            v-model="form.landing_page_button_text"
                            id="input-10"
                            :maxlength="250"
                          >
                          </b-form-input>
                          <div class="text-right">
                            <p
                              v-if="form.landing_page_button_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success':
                                  form.landing_page_button_text.length !== 250,
                                'badge-danger':
                                  form.landing_page_button_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.landing_page_button_text.length }} out of
                              250 chars.
                            </p>
                          </div>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group id="input-group-5" class="col-6">
                          <label for="input-5"
                            >Minimum Passing Criteria (%)
                            <span style="color: red">*</span></label
                          >
                          <b-form-input
                            id="input-5"
                            type="number"
                            min="0"
                            placeholder="Enter Passing Criteria"
                            v-model="form.passing_criteria"
                            :class="{
                              'is-invalid':
                                tabOne && $v.form.passing_criteria.$invalid,
                            }"
                          ></b-form-input>
                          <div
                            v-if="tabOne && !$v.form.passing_criteria.required"
                            class="invalid-feedback"
                          >
                            Passing Criteria is required.
                          </div>
                        </b-form-group>
                        <b-form-group
                          v-if="form.type == 'cme'"
                          class="col-6"
                          id="input-group-6"
                          label="CME Points"
                          label-for="input-8"
                        >
                          <b-form-input
                            id="input-8"
                            type="number"
                            min="0"
                            placeholder="Enter CME Points"
                            v-model="form.points"
                          >
                          </b-form-input>
                        </b-form-group>
                      </div>

                      <b-form-group
                        v-if="form.type == 'survey'"
                        label="Survey URL"
                        label-for="input-4"
                        class="position-relative"
                      >
                        <b-form-input
                          id="input-4"
                          placeholder="Enter Survey URL"
                          v-model="form.survey_url"
                          :maxlength="250"
                        >
                        </b-form-input>
                        <div class="text-right">
                          <p
                            v-if="form.survey_url"
                            class="badge position-absolute"
                            style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.survey_url.length !== 250,
                              'badge-danger': form.survey_url.length === 250,
                            }"
                          >
                            You typed
                            {{ form.survey_url.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <div class="row">
                        <b-form-group
                          class="col-6"
                          v-if="form.type == 'survey'"
                        >
                          <label for="input-9">Coin Type</label>
                          <b-form-select
                            id="input-9"
                            :options="surveyCoins"
                            value-field="value"
                            text-field="text"
                            v-model="form.coins_type"
                          >
                          </b-form-select>
                        </b-form-group>
                        <b-form-group
                          v-if="form.type == 'survey'"
                          class="col-6"
                          id="input-group-12"
                          label="Coins"
                          label-for="input-12"
                        >
                          <b-form-input
                            id="input-12"
                            type="number"
                            min="0"
                            placeholder="Enter Coins"
                            v-model="form.coins"
                          >
                          </b-form-input>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group
                          v-if="form.type == 'quiz'"
                          label="Timer Status"
                          label-for="input-11"
                          class="col-6"
                        >
                          <b-form-select
                            id="input-11"
                            :options="timerstatus"
                            value-field="value"
                            text-field="text"
                            v-model="form.timer_status"
                          >
                          </b-form-select>
                        </b-form-group>
                        <b-form-group
                          v-if="form.type == 'quiz'"
                          class="col-6"
                          id="input-group-12"
                          label="Timer in Seconds"
                          label-for="input-13"
                        >
                          <b-form-input
                            id="input-13"
                            type="number"
                            min="0"
                            placeholder="Enter Timer in Seconds"
                            v-model="form.time_in_seconds"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group
                          v-if="form.type == 'quiz'"
                          class="col-6"
                          id="input-group-12"
                          label="Negative Mark"
                          label-for="input-14"
                        >
                          <b-form-input
                            id="input-14"
                            type="number"
                            min="0"
                            placeholder="Enter Negative Mark"
                            v-model="form.negative_mark"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          v-if="form.type == 'quiz'"
                          class="col-6"
                          id="input-group-12"
                          label="Positive Mark"
                          label-for="input-15"
                        >
                          <b-form-input
                            id="input-15"
                            type="number"
                            min="0"
                            placeholder="Enter Positive Mark"
                            v-model="form.positive_mark"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group class="col-6 position-relative">
                          <label for="input-21"
                            >Pass Text <span style="color: red">*</span></label
                          >
                          <b-form-input
                            id="input-21"
                            :maxlength="250"
                            placeholder="Enter Pass Text [Eg: Thank You]"
                            v-model="form.pass_text"
                            :class="{
                              'is-invalid':
                                tabOne && $v.form.pass_text.$invalid,
                            }"
                          ></b-form-input>
                          <div
                            v-if="tabOne && !$v.form.pass_text.required"
                            class="invalid-feedback"
                          >
                            Pass Text is required.
                          </div>
                          <div class="text-right">
                            <p
                              v-if="form.pass_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success': form.pass_text.length !== 250,
                                'badge-danger': form.pass_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.pass_text.length }} out of 250 chars.
                            </p>
                          </div>
                        </b-form-group>
                        <b-form-group class="col-6 position-relative">
                          <label for="input-22"
                            >Pass Button Text
                            <span style="color: red">*</span></label
                          >
                          <b-form-input
                            id="input-22"
                            :maxlength="250"
                            placeholder="Enter Pass Button Text [Eg: Finish]"
                            v-model="form.pass_button_text"
                            :class="{
                              'is-invalid':
                                tabOne && $v.form.pass_button_text.$invalid,
                            }"
                          ></b-form-input>
                          <div
                            v-if="tabOne && !$v.form.pass_button_text.required"
                            class="invalid-feedback"
                          >
                            Pass Button Text is required.
                          </div>
                          <div class="text-right">
                            <p
                              v-if="form.pass_button_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success':
                                  form.pass_button_text.length !== 250,
                                'badge-danger':
                                  form.pass_button_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.pass_button_text.length }} out of 250
                              chars.
                            </p>
                          </div>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group
                          class="col-6 position-relative"
                          label="Fail Text"
                          label-for="input-23"
                        >
                          <b-form-input
                            :maxlength="250"
                            id="input-23"
                            placeholder="Enter Fail Text"
                            v-model="form.fail_text"
                          >
                          </b-form-input>
                          <div class="text-right">
                            <p
                              v-if="form.fail_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success': form.fail_text.length !== 250,
                                'badge-danger': form.fail_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.fail_text.length }} out of 250 chars.
                            </p>
                          </div>
                        </b-form-group>
                        <b-form-group
                          class="col-6 position-relative"
                          label="Fail Button Text"
                          label-for="input-24"
                        >
                          <b-form-input
                            id="input-24"
                            placeholder="Enter Fail Button Text"
                            v-model="form.fail_button_text"
                            :maxlength="250"
                          >
                          </b-form-input>
                          <div class="text-right">
                            <p
                              v-if="form.fail_button_text"
                              class="badge position-absolute"
                              style="top: 4px; right: 13px"
                              :class="{
                                'badge-success':
                                  form.fail_button_text.length !== 250,
                                'badge-danger':
                                  form.fail_button_text.length === 250,
                              }"
                            >
                              You typed
                              {{ form.fail_button_text.length }} out of 250
                              chars.
                            </p>
                          </div>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group
                          class="col-6"
                          v-if="form.type == 'survey'"
                        >
                          <label for="input-3">Registration Template </label>
                          <b-form-select
                            id="input-3"
                            :options="registrations"
                            value-field="id"
                            text-field="template_name"
                            v-model="form.registration_template_id"
                          >
                          </b-form-select>
                        </b-form-group>
                        <b-form-group class="col-6" v-if="form.type == 'cme'">
                          <label for="input-25">Certificate Template </label>
                          <b-form-select
                            id="input-25"
                            value-field="id"
                            text-field="template_name"
                            v-model="form.certificate_template_id"
                            :options="certificateName"
                          >
                          </b-form-select>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group id="input-4" class="col-6">
                          <label for="input-grp-4">Allowed Member From</label>
                          <b-form-select
                            id="input-grp-4"
                            @change="changeMember"
                            v-model="form.allowed_members_from"
                            value-field="value"
                            text-field="text"
                            :options="allowedMembers"
                          >
                          </b-form-select>
                        </b-form-group>

                        <template
                          v-if="
                            form.allowed_members_from == 'data_filter' &&
                            form.allowed_members_from != 'all'
                          "
                        >
                          <b-form-group
                            id="example-tel"
                            label="Data Filter"
                            label-for="i10"
                            class="col-6"
                          >
                            <b-form-select
                              id="i10"
                              v-model="form.data_filter_id"
                              value-field="id"
                              text-field="name"
                              :options="dataFilter"
                            >
                            </b-form-select>
                          </b-form-group>
                        </template>

                        <template
                          v-if="
                            form.allowed_members_from == 'csv' &&
                            form.allowed_members_from != 'all'
                          "
                        >
                          <b-form-group id="input-group-14" class="col-6">
                            <div class="d-flex justify-content-between">
                              <label for="input-29">Upload CSV</label>
                              <a href="/sample_csv_members.csv" download>
                                <i class="fa fa-download"></i> Download Sample
                                CSV
                              </a>
                            </div>
                            <b-form-file
                              id="input-29"
                              placeholder="Choose a file or drop it here ..."
                              @change="readFile($event, 'members_csv_file')"
                            ></b-form-file>
                            <template
                              v-if="
                                $route.name == 'edit-cme' &&
                                edit.members_csv_file_url != null &&
                                edit.members_csv_file_url != ''
                              "
                            >
                              <a :href="edit.members_csv_file_url">
                                Download CSV File</a
                              >
                            </template>
                          </b-form-group>
                        </template>
                      </div>

                      <div class="row">
                        <b-form-group
                          id="input-group-4"
                          label="Landing Page Image [Upload Max File Size : 2MB]"
                          label-for="input-16"
                          class="col-4"
                        >
                          <b-form-file
                            id="input-16"
                            accept="image/*"
                            placeholder="Choose a file or drop it here... "
                            @change="readFile($event, 'landing_page_image')"
                            ref="landing_page_image"
                          ></b-form-file>
                          <template
                            v-if="
                              $route.name == 'edit-cme' &&
                              edit.landing_page_image_url
                            "
                          >
                            <img
                              :src="edit.landing_page_image_url"
                              width="128px"
                              height="128px"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                          <template v-if="landing_page_image_url">
                            <img
                              :src="landing_page_image_url"
                              width="128px"
                              height="128px"
                              ref="landing_page_image_url"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                        </b-form-group>
                        <b-form-group
                          id="input-group-9"
                          label="Mobile Background [Upload Max File Size : 2MB]"
                          label-for="input-18"
                          class="col-4"
                        >
                          <b-form-file
                            id="input-18"
                            accept="image/*"
                            placeholder="Choose a file or drop it here... "
                            @change="
                              readFile($event, 'survey_background_mobile_image')
                            "
                            ref="survey_background_mobile_image"
                          ></b-form-file>
                          <template
                            v-if="
                              $route.name == 'edit-cme' &&
                              edit.survey_background_mobile_image_url
                            "
                          >
                            <img
                              :src="edit.survey_background_mobile_image_url"
                              width="128px"
                              height="128px"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                          <template v-if="survey_background_mobile_image_url">
                            <img
                              :src="survey_background_mobile_image_url"
                              width="128px"
                              height="128px"
                              ref="survey_background_mobile_image_url"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                        </b-form-group>
                        <b-form-group
                          class="col-4"
                          id="input-group-8"
                          label="Desktop Background [Upload Max File Size : 2MB]"
                          label-for="input-17"
                        >
                          <b-form-file
                            id="input-17"
                            accept="image/*"
                            placeholder="Choose a file or drop it here... "
                            @change="
                              readFile($event, 'survey_background_image')
                            "
                            ref="survey_background_image"
                          ></b-form-file>
                          <template
                            v-if="
                              $route.name == 'edit-cme' &&
                              edit.survey_background_image_url
                            "
                          >
                            <img
                              :src="edit.survey_background_image_url"
                              width="128px"
                              height="128px"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                          <template v-if="survey_background_image_url">
                            <img
                              :src="survey_background_image_url"
                              width="128px"
                              height="128px"
                              ref="survey_background_image_url"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                        </b-form-group>
                      </div>

                      <div class="row">
                        <b-form-group
                          id="input-group-11"
                          label="Pass Image [Upload Max File Size : 2MB]"
                          label-for="input-19"
                          class="col-6"
                        >
                          <b-form-file
                            id="input-19"
                            accept="image/*"
                            placeholder="Choose a file or drop it here... "
                            @change="readFile($event, 'pass_image')"
                            ref="pass_image"
                          ></b-form-file>
                          <template
                            v-if="
                              $route.name == 'edit-cme' && edit.pass_image_url
                            "
                          >
                            <img
                              :src="edit.pass_image_url"
                              width="128px"
                              height="128px"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                          <template v-if="pass_image_url">
                            <img
                              :src="pass_image_url"
                              width="128px"
                              height="128px"
                              ref="pass_image_url"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                        </b-form-group>
                        <b-form-group
                          id="input-group-14"
                          class="col-6"
                          label="Fail Image [Upload Max File Size : 2MB]"
                          label-for="input-20"
                        >
                          <b-form-file
                            id="input-20"
                            accept="image/*"
                            placeholder="Choose a file or drop it here... "
                            @change="readFile($event, 'fail_image')"
                            ref="fail_image"
                          ></b-form-file>
                          <template
                            v-if="
                              $route.name == 'edit-cme' && edit.fail_image_url
                            "
                          >
                            <img
                              :src="edit.fail_image_url"
                              width="128px"
                              height="128px"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                          <template v-if="fail_image_url">
                            <img
                              :src="fail_image_url"
                              width="128px"
                              height="128px"
                              ref="fail_image_url"
                              style="object-fit: contain; margin-top: 5px"
                            />
                          </template>
                        </b-form-group>
                      </div>

                      <b-form-group id="input-group-10">
                        <div style="display: flex; gap: 10px">
                          <b-form-checkbox v-model="form.show_landing_page"
                            >Display Landing Page</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.show_direct_question"
                            >Show Direct Question</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.allow_back"
                            >Back Allowed</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.allow_retest"
                            >Retest Allowed</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.show_result"
                            >Display Score</b-form-checkbox
                          >
                          <b-form-checkbox
                            v-if="form.type == 'cme'"
                            v-model="form.download_certificate"
                            >Download Certificate</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.show_rand_questions"
                            >Show Random Questions</b-form-checkbox
                          >
                          <b-form-checkbox v-model="form.status"
                            >Status</b-form-checkbox
                          >
                          <b-form-checkbox
                            v-if="form.type == 'quiz'"
                            v-model="form.negative_marks_status"
                            >Negative Mark Status</b-form-checkbox
                          >
                        </div>
                      </b-form-group>
                    </b-form>
                  </div>
                </div>
              </tab-content>
              <!-- :before-change="() => validateFormTwo()" -->
              <tab-content
                icon="mdi mdi-file-image"
                :before-change="() => validateFormTwo()"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex align-items-center">
                      <i class="mdi mdi-file-plus-outline mr-2"></i>
                      <h6 class="mb-0">Add CME Question</h6>
                    </div>
                    <b-form-group class="my-2">
                      <label for="input-2"
                        >Question <span style="color: red">*</span></label
                      >
                      <div
                        class="row"
                        v-for="(val, index) in form.questions"
                        :key="index"
                      >
                        <div class="col-7">
                          <b-form-group>
                            <multiselect
                              v-model="val.id"
                              @search-change="fetchQuestion"
                              :options="questionName"
                              :multiple="false"
                              track-by="id"
                              label="question"
                              placeholder="Type here to search"
                            >
                              <span slot="noOptions">
                                Type here to search
                              </span>
                            </multiselect>
                          </b-form-group>
                        </div>
                        <div class="col-3">
                          <b-form-group id="input-group-12">
                            <b-form-checkbox v-model="val.show_answer"
                              >Display Correct Answer</b-form-checkbox
                            >
                            <b-form-checkbox v-model="val.show_answer_details"
                              >Display Answer Details</b-form-checkbox
                            >
                          </b-form-group>
                        </div>
                        <div class="col-2">
                          <b-button
                            variant="success"
                            class="mr-3 btn-sm"
                            :disabled="val.id == 0"
                            @click.prevent="addQuestion(index)"
                            >+</b-button
                          >
                          <b-button
                            variant="danger"
                            class="btn-sm"
                            @click.prevent="removeQuestion(index)"
                            >-</b-button
                          >
                        </div>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </tab-content>
              <tab-content
                icon="mdi mdi-content-copy"
                :before-change="() => validateFormThree()"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex align-items-center">
                      <i class="mdi mdi-file-plus-outline mr-2"></i>
                      <h6 class="mb-0">Add New Attachment</h6>
                    </div>
                    <div v-for="(val, index) in form.attachments" :key="index">
                      <div class="row">
                        <b-form-group class="col-6">
                          <label for="input-26"
                            >Attachment Type
                            <span style="color: red">*</span></label
                          >
                          <template
                            v-if="form.type == 'quiz' || form.type == 'cme'"
                          >
                            <b-form-select
                              id="input-26"
                              value-field="value"
                              text-field="text"
                              v-model="val.map_type"
                              :options="attachType"
                              @change="changeType"
                            >
                            </b-form-select>
                          </template>
                          <template v-if="form.type == 'survey'">
                            <b-form-select
                              id="input-26"
                              value-field="value"
                              text-field="text"
                              v-model="val.map_type"
                              :options="surveyAttachType"
                              @change="changeSurveyType"
                            >
                            </b-form-select>
                          </template>
                        </b-form-group>
                        <template
                          v-if="form.type == 'quiz' || form.type == 'cme'"
                        >
                          <b-form-group
                            class="col-6"
                            v-if="
                              val.map_type != null && val.map_type != 'home'
                            "
                            :label="
                              val.map_type == 'video'
                                ? 'Video'
                                : val.map_type == 'reading_material'
                                ? 'Reading Material'
                                : val.map_type == 'forum'
                                ? 'Forum'
                                : val.map_type == 'live_event'
                                ? 'Live Event'
                                : val.map_type == 'during_live_event'
                                ? 'During Live Event'
                                : val.map_type == 'live_event_registration'
                                ? 'Live Event Registration'
                                : ''
                            "
                            label-for="type2"
                          >
                            <template
                              v-if="
                                val.map_type == 'video' ||
                                val.map_type == 'reading_material' ||
                                val.map_type == 'forum' ||
                                val.map_type == 'live_event' ||
                                val.map_type == 'during_live_event' ||
                                val.map_type == 'live_event_registration'
                              "
                            >
                              <multiselect
                                v-model="val.map_type_id"
                                @search-change="fetchAttachment"
                                :options="attachment"
                                :multiple="false"
                                track-by="id"
                                label="title"
                                placeholder="Type here to search"
                              >
                                <span slot="noOptions">
                                  Type here to search
                                </span>
                              </multiselect>
                            </template>
                          </b-form-group>
                        </template>
                        <template v-if="form.type == 'survey'">
                          <b-form-group
                            class="col-6"
                            :label="val.map_type == 'forum' ? 'Forum' : ''"
                          >
                            <template
                              v-if="
                                val.map_type == 'forum' && val.map_type != null
                              "
                            >
                              <multiselect
                                v-model="val.map_type_id"
                                @search-change="fetchAttachment"
                                :options="attachment"
                                :multiple="false"
                                track-by="id"
                                label="title"
                                placeholder="Type here to search"
                              >
                                <span slot="noOptions">
                                  Type here to search
                                </span>
                              </multiselect>
                            </template>
                          </b-form-group>
                        </template>
                      </div>
                      <div class="row">
                        <b-form-group
                          class="col-6"
                          id="input-group-12"
                          label="When To Show (%)"
                          label-for="input-27"
                        >
                          <b-form-input
                            id="input-27"
                            type="number"
                            min="0"
                            placeholder="Enter percentage (%) of showing CME on attachment"
                            v-model="val.when_to_show"
                          >
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import cmeMixin from "../../../mixins/ModuleJs/cme";
import { FormWizard, TabContent } from "vue-form-wizard";

import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      finalModel: {},
      tabOne: false,
      tabThree: false,
      submitted: false,
      interval: 0,
      slide: 0,
      sliding: null,
      title1: "Add CME",
      title2: "Edit CME",
      fields: [
        {
          key: "link_id",
          label: "Case Link ID",
        },
        {
          key: "images",
          label: "Item Image",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      items: [
        {
          text: "Back",
          href: "/cme",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, cmeMixin],
  validations: {
    form: {
      type: { required },
      name: { required },
      heading_text: { required },
      pass_text: { required },
      pass_button_text: { required },
      description: { required },
      passing_criteria: { required },
    },
  },
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
  },
  methods: {
    validateFormOne() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.tabOne = true;
      if (
        this.$v.form.type.$invalid ||
        this.$v.form.name.$invalid ||
        this.$v.form.description.$invalid ||
        this.$v.form.heading_text.$invalid ||
        this.$v.form.passing_criteria.$invalid ||
        this.$v.form.pass_text.$invalid ||
        this.$v.form.pass_button_text.$invalid
      ) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      if (
        this.form.questions.filter((a) => a.id == "" || a.id == null).length
      ) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Question is Required",
        });
        return false;
      }
      return true;
    },
    validateFormThree() {
      this.tabThree = true;
      if (
        this.form.attachments.filter(
          (a) => a.map_type == "" || a.map_type == null
        ).length
      ) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Attachment Type is required",
        });
        return false;
      }
      if (
        this.form.attachments.filter(
          (a) => a.map_type != "home" && a.map_type != "dgmr_project"
        ).length
      ) {
        if (
          this.form.attachments.filter(
            (a) => a.map_type_id == "" || a.map_type_id == null
          ).length
        ) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Attachment is required",
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>
